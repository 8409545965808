<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pt-2 px-3 pb-4" color="primary">
          <v-card class="my-3">
          <v-card-title class="text-h3">{{ planSummary.title }}</v-card-title>
          <v-card-text>{{ planSummary.description }}</v-card-text>
          <v-card-text>
            <ul>
              <li v-for="benefit in planSummary.benefits" :key="planSummary.benefits">
                {{ benefit }}
              </li>
            </ul>
          </v-card-text>
        </v-card>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" md="6">
        <v-card class="pt-2 px-3 pb-4" color="primary">
          <v-card class="my-3">
            <v-card-title class="text-h4 font-weight-bold">Monthly Subscription</v-card-title>
            <v-card-text class="text-h5 red--text font-weight-bold">${{ planSummary.monthly }}/month</v-card-text>
            <v-card-text>Pay month to month, cancel anytime.</v-card-text>
          </v-card>
          <v-btn block color="white" @click="newSubCheckoutCall(planSummary.priceIdMonth)">Purchase Plan</v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" md="6">
        <v-card class="pt-2 px-3 pb-4" color="primary">
          <v-card class="my-3">
            <v-card-title class="text-h4 font-weight-bold">Annual Subscription</v-card-title>
            <v-card-text class="text-h5 red--text font-weight-bold">${{ planSummary.annual }}/year <span class="text--secondary">Save {{Math.round(((planSummary.monthly*12)-planSummary.annual)/(planSummary.monthly*12)*100)}}%</span></v-card-text>
            <v-card-text>Pay for a year, get a discount, cancel anytime.</v-card-text>
          </v-card>
          <v-btn block color="white" @click="newSubCheckoutCall(planSummary.priceIdAnual)">Purchase Plan</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "SubSummary",
  computed: {
    ...mapState('user', ['currentUser']),
    planSummary() {
      if (this.$route.params.planID) {
        switch (this.$route.params.planID) {
          case 1:
            return {
              title: "Micro Business",
              description: "A step up from free offering more parts for enthusiasts who have a fair amount of spares around.",
              monthly: 10,
              annual: 50,
              partAllow: 100,
              benefits: [],
              priceIdMonth: "price_1MsOPyETCyIBQKP4XeN8dBe4",
              priceIdAnual: "price_1MsOPyETCyIBQKP4dMXGMXcm"
            }
          case 2:
            return {
              title: "Small Business",
              description: "Intended for small businesses, this plan offers enough parts for shops wanting to sell frequently and list most of their stock.",
              monthly: 25,
              annual: 250,
              partAllow: 500,
              benefits: [],
              priceIdMonth: "price_1MsOPpETCyIBQKP4v5ywRo7a",
              priceIdAnual: "price_1MsOPqETCyIBQKP4enAHfexO"
            }
          case 3:
            return {
              title: "Medium Business",
              description: "Medium Businesses require listings that really show off their full catalog of stock to their audience.",
              monthly: 45,
              annual: 500,
              partAllow: 1000,
              benefits: [],
              priceIdMonth: "price_1MsdsrETCyIBQKP4j5IX4vCR",
              priceIdAnual: "price_1MsOPiETCyIBQKP4VnbS7WcP"
            }
          case 4:
            return {
              title: "Large Business",
              description: "Intended for large scaling stores that are constantly turning out stock and need to list a wider range of parts.",
              monthly: 425,
              annual: 5000,
              partAllow: 10000,
              benefits: [],
              priceIdMonth: "price_1MsdqWETCyIBQKP4HyQGCPqJ",
              priceIdAnual: "price_1MsOPXETCyIBQKP4E5WCKS5p"
            }
        }
      } else {
        this.alerts({
          type: "error",
          msg: "To purchase a subscription, please access it from the dashboard",
        });
        this.$router.go(-1)
        return {title: "Unknown Plan"}
      }
    }
  },
  created() {
    this.pwdCheckCall();
    this.checkTier();
  },
  methods: {
    ...mapActions("subscriptions", ["newSubCheckout"]),
    ...mapActions('user', ["pwdCheck"]),
    ...mapActions("alert", ["alerts"]),
    checkTier() {
      if (this.currentUser.usersTier === this.$route.params.planID) {
        this.alerts({
          type: "error",
          msg: "You are trying to purchase a subscription you already own.",
        });
        this.$router.go(-1)
      }
      if (this.$route.params.planID === '0') {
        this.alerts({
          type: "error",
          msg: "You are trying to purchase a free subscription. If you wish to cancel your order, go to Dashboard then 'Manage Subscription'.",
        });
        this.$router.go(-1)
      }
    },
    async pwdCheckCall() {
      let response = await this.pwdCheck();
      if (response["error"] === "userNotFound") {
        this.alerts({
          type: "error",
          msg: "We had trouble finding the user, make sure you are logged in, or login again",
        });
      } else if (response["error"] === "stmtFailed") {
        this.alerts({
          type: "error",
          msg: "It seems something has failed on our end, try again or at a later time",
        });
      } else if (response["error"] === "noPostData") {
        this.alerts({
          type: "error",
          msg: "There was an issue sending data, please try again or login again",
        });
      }
      if (response["correct"] === true) {
      } else if (response["correct"] === false) {
        this.alerts({
          type: "warning",
          msg: "Please sign up before getting a subscription, if you already have an account, please login.",
        });
        await this.$router.push({name: "SignUp"});
      }
    },
    newSubCheckoutCall(priceId) {
      this.newSubCheckout(priceId)
    }
  }
}
</script>

<style scoped>

</style>